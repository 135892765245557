import * as yup from 'yup';

export const updateKycSchema = yup.object({
	status: yup
		.object({
			label: yup.string().required(),
			value: yup
				.string()
				.required()
				.matches(/^(NOT_COMPLETED|COMPLETED)$/),
		})
		.required(),
});
