import RightContainer from 'view/subcomponents/containers/right-container/right-container';
import { useQueryContext } from 'view/subcomponents/providers/query-provider';
import KycCard from 'view/components/kyc/subcomponents/kyc-card';

const KycRightContainer = () => {
	const { data } = useQueryContext();

	return <RightContainer displayTopInfo={true}>{data != null && <KycCard kyc={data} />}</RightContainer>;
};

export default KycRightContainer;
