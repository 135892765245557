import headers from 'api/util/headers';
import apiInstance from 'api/api-instance';

const KycAPI = {
	getKycById: data => apiInstance.get('/api/kyc/getKycById', data, headers()),
	updateArchived: data => apiInstance.post('/api/kyc/updateArchivedAdmin', data, headers()),
	updateStatus: data => apiInstance.post('/api/kyc/updateStatusAdmin', data, headers()),
};

export default KycAPI;
