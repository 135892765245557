import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Dialog, DialogContent, DialogTitle } from '@mui/material';
import api from 'api/api';
import { KycStatuses } from 'config/constants/kys';
import { t } from 'i18next';
import React, { useState } from 'react';
import { useForm, useFormState } from 'react-hook-form';
import { SnackbarTypes } from 'types/snackbar';
import { updateKycSchema } from 'utils/validation/schema/update-kyc-schema';
import PendingButton from 'view/subcomponents/buttons/pending-button';
import { Form } from 'view/subcomponents/form';
import { KycAutocomplete } from 'view/subcomponents/input/autocomplete/kyc-autocomplete';
import { useQueryContext } from 'view/subcomponents/providers/query-provider';
import { useSnackbar } from 'view/subcomponents/providers/snackbar-provider';

const ChangeKycStatusDialog = ({ open, setOpen }) => {
	const [pending, setPending] = useState(false);
	const { data, updateData } = useQueryContext();
	const { enqueue } = useSnackbar();

	const { setValue, ...methods } = useForm({
		defaultValues: {},
		resolver: yupResolver(updateKycSchema),
	});

	const onSubmit = async ({ status }) => {
		setPending(true);
		const result = await api.updateStatus({ id: data.id, status: status.value });

		if (result.ok) {
			enqueue(SnackbarTypes.Success, 'Kyc.UpdateStatus.Success');

			updateData({ status: status.value });
			setOpen(false);
		} else {
			enqueue(SnackbarTypes.Error, 'Kyc.UpdateStatus.Error');
		}
		setPending(false);
	};

	const { isDirty } = useFormState({
		control: methods.control,
	});

	const kycStatuses = [KycStatuses.Completed, KycStatuses.NotCompleted];

	return (
		<Dialog
			open={open}
			onClose={() => setOpen(false)}
			fullWidth={true}
			PaperProps={{
				sx: {
					borderRadius: '16px',
					padding: '8px',
					boxSizing: 'border-box',
					maxWidth: '650px',
				},
			}}
		>
			<DialogTitle
				sx={{
					color: 'primary.main',
					textAlign: 'center',
					fontWeight: 'bold',
				}}
			>
				Uppdatera status
			</DialogTitle>
			<DialogContent>
				<Form
					methods={{ ...methods, setValue }}
					onSubmit={onSubmit}
					sx={{
						marginTop: '16px',
						display: 'flex',
						flexDirection: 'column',
						gap: '16px',
					}}
				>
					<KycAutocomplete
						name={'status'}
						options={Object.values(kycStatuses).map(kycStatus => ({
							value: kycStatus,
							label: t(`kycStatusesSingular.${kycStatus}`),
						}))}
					/>
					<Box
						sx={{
							paddingTop: '8px',
							display: 'flex',
							justifyContent: 'flex-end',
						}}
					>
						<PendingButton pending={pending} variant={'purple'} type={'submit'} disabled={!isDirty}>
							Spara
						</PendingButton>
					</Box>
				</Form>
			</DialogContent>
		</Dialog>
	);
};

export default ChangeKycStatusDialog;
