import RightContainerCard from 'view/subcomponents/card/right-container-card/right-container-card';

import { Box } from '@mui/material';
import { Kyc } from 'types/kyc';
import KycCardContent from 'view/components/kyc/subcomponents/kyc-card-content';
import KycCardHeader from 'view/components/kyc/subcomponents/kyc-card-header';
import KycCardFooter from 'view/components/kyc/subcomponents/kyc-card-footer';
import ChangeKycStatusDialog from 'view/components/kyc/subcomponents/change-kyc-status-dialog';
import { useState } from 'react';

interface Props {
	kyc: Kyc;
}

const KycCard = ({ kyc }: Props) => {
	const [open, setOpen] = useState<boolean>(false);

	return (
		<RightContainerCard>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					width: '100%',
				}}
			>
				<KycCardHeader kyc={kyc} />
				<KycCardContent kyc={kyc} />
				<KycCardFooter kyc={kyc} setOpen={setOpen} />
			</Box>

			<ChangeKycStatusDialog open={open} setOpen={setOpen} />
		</RightContainerCard>
	);
};

export default KycCard;
